.mainMenu {
  position: fixed !important;
  display: flex !important;
  margin: 0 !important;
  border-radius: 0 !important;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  width: 300px !important;
  overflow-x: hidden;
  border: 0 !important;
  border-right: 1px solid rgba(34, 36, 38, 0.15) !important;
  z-index: 1;

  &__divider {
    flex-grow: 1;
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  }

  &.inverted {
    .mainMenu__divider {
      border-top: 1px solid rgba(255, 255, 255, 0.08);
    }
  }

  & > h1 {
    & > a {
      width: 150px;
      margin: 0 auto !important;
    }

    .label {
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }

  &.inverted {
    border-right: 1px solid rgba(200, 200, 200, 0.15) !important;
  }

  .item {
    font-size: 18px;
    font-weight: normal;
  }
}

.mainContainer {
  margin-left: 300px !important;
  padding: 40px 40px !important;
  min-width: 800px;
}
