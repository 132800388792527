.informationPanel {
  margin-top: 2em;
  margin-bottom: 2em;
  position: initial;

  .progress {
    margin-top: -1em !important;
    margin-bottom: 1em !important;
    margin-left: -1.5em !important;
    margin-right: -1.5em !important;
  }
}
