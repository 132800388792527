.pageContainer {
  max-width: 1000px !important;

  &__history-toggle {
    padding: 0 !important;
    background: 0 !important;

    .button {
      margin-right: 0 !important;
    }
  }

  &__history-panel {
    width: 700px !important;
    z-index: 1001 !important;
    padding: 20px !important;
    box-shadow: none !important;

    &.inverted {
      border-left: 1px solid rgba(200, 200, 200, 0.15) !important;
    }
  }
}
