.log-table-status {
  position: relative;

  &__aborted {
    position: absolute;
    color: rgba(100, 100, 100, 0.6);
    font-weight: bolder !important;
    top: 0;
    left: -20px;
  }

  &__progress {
    margin: 0 !important;
  }

  &__progress-label {
    position: absolute !important;
    font-size: 10px !important;
    top: -3px !important;
    left: -6px !important;
  }
}
