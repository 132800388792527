body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: initial !important;

  #root {
    text-align: left;
  }

  pre {
    white-space: pre-wrap;
  }

  .formActionGroup {
    margin-top: 40px !important;
  }

  .ui {
    &.segment {
      &.no-horizontal-padding {
        padding-left: 0;
        padding-right: 0;
      }

      &.inverted {
        &.checkbox {
          input:focus ~ label {
            color: rgba(255, 255, 255, 0.8) !important;
          }

          label:hover {
            color: rgba(255, 255, 255, 0.8) !important;
          }
        }

        &.button {
          box-shadow: 0 0 0 2px #666 inset !important;

          &:hover {
            background-color: black !important;
            color: #fff !important;
          }
        }

        &.menu {
          &.tabular {
            border-bottom: 1px solid #d4d4d5 !important;

            .item {
              &.active {
                border-bottom: 1px solid #1b1c1d;
                background-color: #1b1c1d;
              }
            }
          }

          .dropdown {
            .menu {
              background-color: #2d2d2d;

              .header {
                color: #999 !important;
              }

              .item {
                color: #fff !important;

                &.active {
                  background-color: #242424 !important;
                  color: #fff !important;
                }

                &:hover {
                  background-color: #222 !important;
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  @media (prefers-color-scheme: dark) {
    body {
      background-color: #1b1c1d !important;
    }
  }
}
